<template>
  <div class="padding24 container" id="orderList">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div class="top">
        <a-form layout="inline">
          <a-form-item label="订单编号">
            <a-input
              v-model="params.orderId"
              placeholder="请输入订单编号"
              @pressEnter="searchClick"
            ></a-input>
          </a-form-item>
          <a-form-item label="商品名称">
            <a-input
              v-model="params.productName"
              placeholder="请输入商品名称"
              @pressEnter="searchClick"
            ></a-input>
          </a-form-item>
          <a-form-item label="联系手机号">
            <a-input
              v-model="params.phone"
              placeholder="请输入联系手机号"
              @pressEnter="searchClick"
            ></a-input>
          </a-form-item>
          <a-form-item label="支付时间">
            <a-range-picker
              :value="rangePicker"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              @change="onChange"
            />
          </a-form-item>
          <a-form-item label="登记状态">
            <a-select
              v-model="params.checkInStatus"
              style="width: 100px"
              @change="val => handleSelectChange('checkInStatus', val)"
            >
              <a-select-option
                v-for="item in checkInStatusArr"
                :key="item.key"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button
              icon="search"
              type="primary"
              @click="searchClick"
              :disabled="isDisableSearch"
            >
              搜索
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button icon="redo" type="primary" @click="resetClick">
              重置
            </a-button>
          </a-form-item>
        </a-form>
        <!-- <a-form layout="inline">
          <a-form-item>
            <a-button
              v-if="isShowOrderExport"
              icon="export"
              type="primary"
              @click="exportClick"
            >
              导出
            </a-button>
          </a-form-item>
        </a-form> -->
      </div>
      <!-- 表格 -->
      <a-table
        :columns="registerListColumns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        row-key="id"
        :scroll = "{ x: 1500}"
      >
        <span slot="operation" slot-scope="text, record">
          <a-button type="link" @click="handleDetail(record)">修改信息</a-button>
        </span>
        <span slot="contacts" slot-scope="text, record">
          <div v-for="item in record.contacts">{{ item.name }}: {{ item.idCard }}</div>
        </span>
      </a-table>
      <MyPagination
        :count="count"
        :pageNo="params.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </a-spin>

    <UpdateModal :visible="showModal" :info="currentRecord" @close="showModal = false" />
  </div>
</template>

<script>
import moment from 'moment'
import { registerListColumns, checkInStatusArr, orderStatusArr } from '../constants'
import { researchCheckinList } from '@/request/api/tiktok'
import MyPagination from '@/components/pagination/MyPagination'
import { codeFn } from '@/utils/tools'
import UpdateModal from './components/updateModal.vue'

export default {
  components: {
    MyPagination,
    UpdateModal
  },
  created() {
    this.fetchData()
    this.isShowOrderExport = codeFn('listenerOrderExport')
  },
  data() {
    return {
      showModal: false,
      currentRecord: {},
      spinning: false, // 全局loading
      tip: '',
      params: {
        pageNo: 1,
        pageSize: 20,
        orderId: '',
        productName: '', // 商品名称
        orderStatus: 0, // 订单状态的默认值
        checkInStatus: 0,
        dateStart: '',
        dateEnd: ''
      },
      rangePicker: [], // 下单日期数组
      count: 0,
      isDisableSearch: false, // 搜索是否禁用
      tableLoading: true,
      registerListColumns,
      tableData: [],
      isShowOrderExport: false, // 同步订单是否隐藏
      form: {},
      checkInStatusArr, // 登记状态
      orderStatusArr, // 订单状态
    }
  },
  methods: {
    moment,
    // 商品类型改变的回调
    changeOrderType(value) {
      this.pageNo = 1;
      this.orderType = value;
      this.fetchData();
    },
    // 下拉选择回调
    handleSelectChange(key, value) {
      this.pageNo = 1;
      this.params[key] = value;
      this.fetchData();
    },
    // 下单日期改变的回调
    onChange(date, dateString) {
      if (dateString[0] !== '') {
        this.params.dateStart = dateString[0];
        this.params.dateEnd = dateString[1];
        this.rangePicker = [
          moment(dateString[0], "YYYY-MM-DD HH:mm:ss"),
          moment(dateString[1], "YYYY-MM-DD HH:mm:ss"),
        ];
      } else {
        this.params.dateStart = ''
        this.params.dateEnd = ''
        this.rangePicker = [];
      }
    },
    // 搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.fetchData();
    },
    // 重置按钮
    resetClick() {
      this.params = this.$options.data().params
      this.rangePicker = [];
    },
    // 导出订单按钮
    async exportClick() {
      this.tip = '订单导出中'
      this.spinning = true
      const params = this.params
      const { code, data } = await exportCompanyAPI(params)
      this.spinning = false
      if (code !== 200) return
      window.location.href = data
      this.spinning = false
      this.$message.success('导出订单成功')
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.params.pageNo = current
      this.params.pageSize = pageSize
      this.fetchData()
    },
    // 查看详情
    handleDetail(record) {
      this.showModal = true
      this.currentRecord = record
    },
    // 获取第三方订单列表
    async fetchData() {
      this.tableLoading = true
      const params = this.params
      const { code, data } = await researchCheckinList(params)
      this.tableLoading = false
      if (code !== 200) return
      this.count = data.count;
      const checkinList = data.checkinList
      checkinList.forEach(item => {
        const checkinInfo = item.checkinInfo
        if (checkinInfo) {
          Object.assign(item, checkinInfo)
        }
      });
      this.tableData = data.checkinList
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#orderList");
    },
  },
};
</script>
 
<style lang="less" scoped>
.top {
  .top_line {
    margin-bottom: 20px;
    display: flex;
    span {
      margin-right: 20px;
      /deep/.ant-input {
        width: 220px;
      }
      /deep/.ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }
      /deep/.ant-calendar-picker-input {
        margin-left: 10px;
        width: auto;
      }
      /deep/.ant-calendar-picker {
        width: 400px !important;
      }
    }
    .ant-btn {
      margin-right: 20px;
    }
  }
}
</style>