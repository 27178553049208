<template>
  <div>
    <a-modal
      title="修改信息"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="null"
      @cancel="handleCancel"
    >
      <a-form-model :model="info" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="出行日期">
          <span class="inline-block">{{ info.goTime }}</span>
          <a-button type="primary" @click="handleChange">修改</a-button>
        </a-form-model-item>
        <a-form-model-item label="出行场次">
          <span class="inline-block">{{ info.times }}</span>
          <a-button type="primary" @click="handleChange">修改</a-button>
        </a-form-model-item>
        <a-form-model-item label="联系方式">
          <a-input type="text" placeholder="请输入手机号码" v-model="info.phone" />
        </a-form-model-item>
        <a-form-model-item label="紧急联系人">
          <a-input type="text" placeholder="请输入紧急联系人手机号" v-model="info.contactPhone" />
        </a-form-model-item>
        <a-form-model-item label="出行人信息">
          <div v-for="(item, index) in info.userList"> {{ index+1 }}. {{ item.name }}: {{ item.cardId }}</div>
          <div class="tips">（姓名+身份证号）</div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="handleOk">
            保存
          </a-button>
          <a-button style="margin-left: 10px;" @click="handleCancel">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      title="修改出行信息"
      :visible="dataPanel"
      :confirm-loading="confirmLoading"
      @cancel="dataPanel = false"
      @ok="handleSelect"
    >
      <div class="container-box">
        <a-calendar :fullscreen="false" :value="defaultValue" @change="onPanelChange" valueFormat="yyyy-MM-DD" />
        <div class="time-info">
          <div class="title">  场次-库存 </div>
          <a-radio-group v-model="info.times" >
            <a-radio class="radio-item" :value="1">
              <span style="margin-right: 30px;">09:00 ~ 10:00</span>
              <span>库存: 20</span>
            </a-radio>
            <a-radio class="radio-item"  :value="2">
              <span style="margin-right: 30px;">09:00 ~ 10:00</span>
              <span>20</span>
            </a-radio>
            <a-radio class="radio-item"  :value="3">
              <span style="margin-right: 30px;">09:00 ~ 10:00</span>
              <span>20</span>
            </a-radio>
            <a-radio class="radio-item"  :value="4">
              <span style="margin-right: 30px;">09:00 ~ 10:00</span>
              <span>20</span>
            </a-radio>
          </a-radio-group>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ModalText: 'Content of the modal',
      confirmLoading: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      dataPanel: false,
      defaultValue: '',
    };
  },
  methods: {
    // 点击修改场次
    handleChange() {
      this.defaultValue = this.info.goTime
      this.dataPanel = true
    },
    // 选择出行信息
    handleSelect() {
      this.dataPanel = false
    },
    // 日历面板变化
    onPanelChange(value) {
      console.log(value)
      this.defaultValue = value
    },
    handleOk(e) {
      this.ModalText = 'The modal will be closed after two seconds';
      this.confirmLoading = true;
      setTimeout(() => {
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      this.$emit('close')
    },
    onSubmit() {
      console.log('submit!', this.form);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-fullcalendar-header .ant-radio-group{
  display: none;
}
.inline-block {
  display: inline-block;
  width: 50%;
}
.tips {
  color: #d6d6d6;
}
.container-box {
  display: flex;
  .time-info {
    margin-left: 20px;
    .title {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
.radio-item {
  display: block;
  margin-top: 10px;
}
</style>